import React, {useState} from 'react'
import '../styles/About.css'

const testimonials = [
    "I was very lucky to have been referred to Corin. She did an excellent job with our place, always kept an open line of communication and most importantly, she was reliable and always showed up! Thank you Corin. If we need anything else done, we will be calling you.",
    
];
  
  const About = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrevClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };
  
    const handleNextClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
    return (
        <>
            <main className='about-main'>
                <div className="about-main-first">
                    <img className='hero-image' src="../assets/images/about-hero.png" alt="" />
                    <div className="about-main-first-content">
                        <h1>Designing and building the whole picture!</h1>
                        <p>Everything For All Your Construction Project, Punch-listing and Site Management Needs</p>
                    </div>
                </div>
                <div className="about-main-second">
                    <div className="main-second-top">
                        <p>Our goals are to <span>help</span> you!</p>
                    </div>
                    <div className="main-second-bottom">
                        <div className="second-bottom-left">
                            <img src="../assets/images/about-one.png" alt="" />
                        </div>
                        <div className="second-bottom-right">
                            <p>The Owner of P.I.I.C.S., Corin Lutz, has a background in construction, demolition, landscaping, architecture, art, and literature. This knowledge, accrued during a lifetime of hands-on experience, allows a unique approach to a vast array of construction needs from common household issues to large commercial projects. This combination of experience and educational background allows a vision of things through the lens of an artist. The application of these skills, operating jointly, has given us a deep understanding of industry standards and the ability to cater to the vision that our clients dream up. Technical expertise ensures that we can then turn that vision into reality.</p>
                            <br />
                            <p>Additionally, an intensive attention to detail and understanding of construction needs and field expectations offers the perfect marriage of ability for practical application on the basics of site operations. Previous experiences working in construction makes us the perfect answer to manage the habits of employees and help keep workers on task. The real estate market in Middle Tennessee has seen a tremendous increase in recent years. While this has been a fantastic time to be in construction, good help can be hard to find. If productivity suffers so does your bottom line.</p>
                        </div>
                    </div>
                </div>
                <div className="about-main-third">
                    <div className="main-third-top">
                        <p>Formerly, as a home inspector, I saw many repairs and additions done by eager but rushed employees, with the unfortunate side effect of producing final products that can later feel like cut corners. It can be very hard to track employee productivity occasionally resulting efforts don't match the company standards. I believed I could put my extensive experience to good use in both house repairs, Punch listing and site observations to sew up the loose ends.</p>
                    </div>
                    <div className="main-third-bottom">
                        <p>— A note from our owner</p>
                    </div>
                </div>
                <div className="about-main-fourth">
                    <div className="main-fourth-left">
                        <p>How It <span>Works:</span></p>
                        <br />
                        <p>From before you move in, we're here for you. Any time, every project, each step of the way.</p>
                    </div>
                    <div className="main-fourth-right">
                        <div className="fourth-right-box">
                            <div className="number">
                                <p>1</p>
                            </div>
                            <div className="fourth-content">
                                <p>Tell us what you need</p>
                                <p>Answer some questions about your project and your end goal desires to get started. This helps us determine pricing and the right pro for the job.</p>
                            </div>
                        </div>
                        <br />
                        <div className="fourth-right-box">
                            <div className="number">
                                <p>2</p>
                            </div>
                            <div className="fourth-content">
                                <p>Book An Appointment</p>
                                <p>We'll review your project details and create an appointment with the best professional to get your project done.</p>
                            </div>
                        </div>
                        <br />
                        <div className="fourth-right-box">
                            <div className="number">
                                <p>3</p>
                            </div>
                            <div className="fourth-content">
                                <p>Get it Done</p>
                                <p>You can sit back and relax while it's done. And of course, we'll be there whenever and wherever you need us.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-main-fifth">
                    <h1><span>Commitment</span> with You</h1>
                    <p>PIICS is committed to giving you excellence with every job. From ground breaking to key turning and everything in between, we are the best option for your projects.</p>
                </div>
                <div className="about-main-sixth">
                    <h1>What our <span>Clients</span> have to say</h1>
                    <br />
                    <div className="main-sixth-content">
                        <button onClick={handlePrevClick}><img src="../assets/icons/arrow-left.svg" alt="Left Arrow" /></button>
                        <p>{testimonials[currentIndex]}</p>
                        <button onClick={handleNextClick}><img src="../assets/icons/arrow-right.svg" alt="Right Arrow" /></button>
                    </div>
                    <br />
                    <h2>Terri Galfano</h2>
                    <p>Realtor/Sales Agent, Coldwell Banker</p>
                </div>
                <div className="about-main-first">
                    <img className='hero-image' src="../assets/images/about-city.png" alt="" />
                    <div className="about-main-seventh-content">
                        <h1>WE HAPPILY AND PROUDLY SERVE ALL OF MIDDLE TENNESSEE!!</h1>
                        <br />
                        <button>Go</button>
                    </div>
                </div>
                <div className="about-main-eight">
                    <div className="eight-left">
                        <p>Interested ?</p>
                        <br />
                        <p>Send us any questions you have about our services and we'll get back to you within 48 hours.</p>
                        <br />
                        <br />
                        <div className="contact-detail-eight">
                            <i class="fa-solid fa-location-dot"></i>
                            <p>Brentwood, TN 37027</p>
                        </div>
                        <br />
                        <div className="contact-detail-eight">
                            <i class="fa-solid fa-phone"></i>
                            <p>(716)-395-5949</p>
                        </div>
                        <br />
                        <div className="contact-detail-eight">
                            <i class="fa-solid fa-envelope"></i>
                            <p>CorinLutz@PIICSTN.com</p>
                        </div>
                    </div>
                    <br />
                    <form className="eight-right">
                        <h1>Fill Your Questions Here</h1>
                        <input type="text" placeholder='Your Name' />
                        <input type="text" placeholder='Your Email' />
                        <textarea id="" cols="30" rows="10" placeholder='Your Question'></textarea>
                        <button>Send Question</button>
                    </form>
                </div>
            </main>
        </>
    )
}

export default About