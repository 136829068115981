import React from "react";

const Homepage = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-white  font-[Montserrat]">
      {/* first division */}
      <div className="w-full md:relative flex flex-cols inherit top-0">
        <div className=" w-full ">
          <img src="../assets/images/home-landing.jpg" alt="Your alt text" className="w-full h-auto object-cover" />
          <div className="relative flex justify-between md:px-20 px-8 md:bottom-32 bottom-20 w-full text-white md:text-6xl text-md font-md">
            <p>Turning your Vision...</p>
            <p>Into Reality</p>
          </div>
        </div>

        {/* <div className="relative md:inset-0 flex flex-col items-center justify-center w-full md:w-3/4 lg:w-1/2 xl:w-2/5 mx-auto border-2">
          
        </div> */}
      </div>



      {/* second division */}
      <div className="flex flex-col justify-center items-center mt-10 w-full">
        <h1 className="text-dark md:text-4xl font-medium md:mt-4 text-center text-2xl">
          Why We're The <span className="text-blue-600">Best</span>
        </h1>

        <div className="flex flex-col items-center justify-center w-full lg:w-1/3 pl-6 mt-4">
          {/* first text division */}
          <div className="text-dark font-medium justify-center mt-4 w-full lg:w-2/3 ">
            <span className="text-blue-500">
              <i className="fa-solid fa-check mr-2 text-blue-600  "></i> Insured and Guaranteed
            </span>
            <p className="text-sm font-[Montserrat] mt-3">
              Our Services are done by Licensed Professionals who are committed to making sure you are happy with every job
            </p>
          </div>
          {/* first text division up to here */}

          {/* second text division */}
          <div className="text-dark font-medium justify-center mt-4 w-full lg:w-2/3">
            <span className="text-blue-600">
              <i className="fa-solid fa-check mr-2"></i>Every Type of Project
            </span>
            <p className="text-sm font-[Montserrat] mt-3">
              We can manage a wide variety of work for you directly, from planning to turning over, we got you covered. We'll catch all the little things that can fall through the cracks
            </p>
          </div>
          {/* second text division up to here */}

          {/* third text division */}
          <div className="text-dark font-medium justify-center mt-4 w-full lg:w-2/3">
            <span className="text-blue-600">
              <i className="fa-solid fa-check mr-2"></i>Contact us 24/7
            </span>
            <p className="text-sm font-[Montserrat] mt-3">
              We are ready to help you 24/7
            </p>
          </div>
          {/* third text division up to here */}
        </div>
      </div>


      {/* second division up to here */}

      {/* third division */}
      <div className="bg-blue-50 w-full flex flex-col items-center justify-center mt-16 py-12 md:py-24">
        <span className="text-center text-lg md:text-xl">
          It's fast, easy, and hassle-free
        </span>
        <h1 className="text-dark md:text-4xl text-3xl font-medium mt-4 md:mt-6 text-center">
          Start Your Project <span className="text-blue-500">Today</span>
        </h1>
        {/* division for grid items */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-8 w-11/12 md:w-4/5 xl:w-3/4 mx-auto">
          {/* first grid division  */}
          <div className="bg-white p-4 rounded-md flex flex-col items-center justify-center group overflow-hidden py-16">
            <i className="fa-regular fa-lightbulb fa-4x mb-4 text-blue-600"></i>
            <span className="mt-4">Tell us Your Idea</span>
            <p className="text-sm font-[Montserrat] mt-3 ml-5 text-center">
              Give us the details of your project, and we can get you your Free
              quote and get started
            </p>
          </div>
          {/* first grid division up to here */}

          {/* second grid divison */}
          <div className="bg-blue-600 p-4 rounded-md flex flex-col items-center justify-center text-white">
            <i className="fa-regular fa-calendar-check fa-4x mb-4 text-white" ></i>
            <span className="mt-4 ">Book Appointment</span>
            <p className="text-sm font-[Montserrat] mt-3 ml-5  text-center">
              We'll review your project details and create an appointment to get your project done.
            </p>
          </div>
          {/* second grid division up to here */}

          {/* third grid division  */}
          <div className="bg-white p-4 rounded-md flex flex-col items-center justify-center group overflow-hidden py-16">
            <i class="fa-solid fa-circle-check mb-4 fa-4x text-blue-600"></i>
            <span className="mt-4">We’ll Get It Done</span>
            <p className="text-sm font-[Montserrat] mt-3 ml-5 text-center">
              You can sit back and relax while it's done. And of course, we'll be there whenever and wherever you need us.
            </p>
          </div>
          {/* third grid division up to here */}
        </div>
        {/* grid division up to here */}
      </div>

      {/* third division upto here */}

      {/* fourth division */}
      <div className="w-full flex flex-col items-center justify-center py-12 md:py-20">
        {/* image grid division */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mt-8 w-11/12 md:w-4/5 lg:w-3/4 mx-auto">
          {/* first image grid division */}
          <div className="rounded-md flex flex-col items-center justify-center">
            <img src="../assets/images/first.png" alt="" className="w-full h-auto" />
          </div>
          {/* first image grid division up to here */}

          {/* second grid division */}
          <div className="rounded-md items-center justify-center mt-8 sm:mt-20">
            <img src="../assets/images/second.png" alt="Your alt text" className="w-full h-auto" />
          </div>
          {/* second grid division up to here */}

          {/* third grid division */}
          <div className="rounded-md flex flex-col items-center justify-center mt-8">
            <img src="../assets/images/third.png" alt="Your alt text" className="w-full h-auto" />
          </div>
          {/* third grid division up to here */}
        </div>
        {/* image grid division up to here */}
      </div>

      {/* fourth division upto here */}

      {/* fifth division form here */}
      <div className="bg-blue-600 rounded-md mx-auto w-11/12 flex flex-col sm:flex-row ml-auto mt-32 sm:mt-20 grid grid-cols-1 sm:grid-cols-2">
        {/* first divison of fifth division */}
        <div className="rounded-md flex flex-col items-center sm:mt-32 mr-auto ml-auto w-full sm:w-3/5 p-5 text-white">
          <span className="text-3xl font-medium mr-auto text-2xl">
            Commitment Gurantee
          </span>
          <p className="text-sm font-[Montserrat] mt-3 ">
            We're committed to giving you excellence with every job. From
            demolition to managing to landscaping to punch-listing and
            everything in between, we'll bring you the best option for your
            projects.
          </p>
          <button className="bg-white text-blue-600 font-medium mr-auto mt-3 mb-2 px-4 py-2 mt-4 rounded-md hover:font-semibold transition duration-300">
            Learn More
          </button>
        </div>

        {/* first division of fifth division up to here */}
        {/* second division of first division */}
        <div className=" ">
          <img src="../assets/images/project.png" alt="Your alt text" />
        </div>
        {/* second division of fifth division up to here */}
      </div>

      {/* fifth division upto here */}

      <div className="py-20 flex flex-col items-center w-full space-y-16 px-4">
        <h1 className="text-4xl font-medium">Testimonial<span className="text-blue-600">s</span></h1>
        <div className="flex flex-wrap justify-center items-start gap-16">
          <div className="flex flex-col item-center w-[320px] py-5 space-y-5 px-5 bg-neutral-100 shadow-lg">
            <p className="w-full text-sm text-neutral-800">
              Corin is amazing. She has done several different jobs from fixing cabinetry to painting a full commercial office. She does absolutely wonderful work and I wouldn't call anyone else.
            </p>
            <p className="text-sm text-neutral-800"><span className="text-sm font-bold">Erin Sinkuler,</span> Owner at Ginkgo Health Studio</p>
          </div>
          <div className="flex flex-col item-center w-[320px] py-5 space-y-5 px-5 bg-neutral-100 shadow-lg">
            <p className="w-full text-sm text-neutral-800">
              Corin has done numerous home improvement/ renovation projects at my house, and I am EXTREMELY pleased with all her work! | was very impressed at how creative she is, and she designed a laundry room for me that was unique and one of kind. She always paid close attention to detail and kept her workspace very tidy. I can go on and on about how wonderful Corin is to work with! She is a sweet and genuine person with great communication skills. And there was always a clear timeline for when the work will be completed. I can't wait to work with Corin on more projects in the future!!
            </p>
            <p className="text-sm text-neutral-800"><span className="text-sm font-bold">Margaux Akright,</span> Homeowner</p>
          </div>
          <div className="flex flex-col item-center w-[320px] py-5 space-y-5 px-5 bg-neutral-100 shadow-lg">
            <p className="w-full text-sm text-neutral-800">
              I have hired Corin to help me with several remodeling project in the Nashville area.
              She is incredible to work with and has an amazing eye for detail. She listens and advises accordingly. Her work is impeccable, and I will continue working with her in the future.
            </p>
            <p className="text-sm text-neutral-800"><span className="text-sm font-bold">Ashley MiddleBrooks,</span> Designer at Ashley Noelle Desings</p>
          </div>
        </div>
      </div>

      {/* sixth diviiosn from here */}
      <div className="bg-white py-14 sm:py-10 mx-2 sm:mx-20 w-full flex flex-col items-center md:flex-row md:justify-center">
        {/* First division */}
        <div className="flex flex-col w-full sm:w-96 sm:mr-8 md:shadow-xl ring-blue-700 p-4 sm:p-10 mb-8 md:mb-0">
          <span className="font-medium text-xl font-semibold">
            Fill your Questions Here
          </span>
          <input
            type="text"
            placeholder="Your name"
            className="border border-solid border-blue-500 rounded-md py-2 px-3 text-sm mt-2"
          />
          <input
            type="text"
            placeholder="Your email"
            className="border border-solid border-blue-500 rounded-md py-2 px-3 text-sm mt-2"
          />
          <textarea
            placeholder="Fill your questions here"
            className="border border-solid border-blue-500 rounded-md py-2 px-3 text-sm mt-2"
            style={{ resize: "none", height: "10em" }}
          ></textarea>
          <button className="bg-blue-600 text-white mt-3 px-5 py-2 rounded-md hover:bg-blue-600 hover:text-white hover:font-semibold">
            Send Question
          </button>
        </div>

        {/* Second division */}
        <div className="flex flex-col text-center md:text-left">
          <span className="font-medium font-semibold text-2xl md:text-3xl">
            Serving the Middle Tennessee Area
          </span>
          <div className="bg-white flex flex-col gap-2 mt-4 md:w-2/3 md:max-w-[420px]">
            <span className="font-medium">
              <i className="fa-solid fa-location-dot mt-1 text-blue-600 mr-4"></i>
              Brentwood, TN 37027
            </span>
            <span className="font-medium">
              <i className="fa-solid fa-phone mt-1 text-blue-600 mr-4"></i>
              716-395-5040
            </span>
            <span className="font-medium">
              <i className="fa-regular fa-message mt-1 text-blue-600 mr-4"></i>
              CorinLutz@PIICSTN.com
            </span>
          </div>
        </div>
      </div>      

      {/* seventh division upto here */}

      <div className="w-full">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103291.92885386034!2d-86.8667392465559!3d35.99897505849203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88647a54e7658aa1%3A0x22b346504468eaa3!2sBrentwood%2C%20TN%2037027%2C%20USA!5e0!3m2!1sen!2snp!4v1704351102702!5m2!1sen!2snp" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: "100%", height: "30rem" }}></iframe>
      </div>
    </div>
  );
};

export default Homepage;
