import React from 'react';
import { Link } from 'react-router-dom';



const Footer = () => {
  return (
    <>

<footer className="bg-blue-600 w-full py-20">
  <div className="text-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9 w-5/6 mx-auto justify-center space-x-4">
    {/* Section 1 */}
    <div className="mt-10">
      <span className="text-2xl font-medium mr-auto font-semibold">
        P.I.I.C.S
      </span>
      <p className="text-sm font-[Montserrat] mt-6 font-medium">
        Property Improvement and Construction Services LLC
      </p>
      <div className="flex grid grid-cols-5 gap-3 mt-8">
        <img src="../assets/images/visa.png" className='cursor-pointer ' alt="Visa" />
        <img src="../assets/images/mastercard.png" className='cursor-pointer ' alt="MasterCard" />
        <img src="../assets/images/amex.png"className='cursor-pointer ' alt="American Express" />
        <img src="../assets/images/discover.png" className='cursor-pointer ' alt="Discover" />
      </div>
    </div>

    {/* Section 2 */}
    <div className="flex flex-col mt-10 md:pl-16">
      <span className="text-xl font-medium">Company</span>
      <div className="flex flex-col w-full text-white">
      <Link to = "/" className="mt-2 font-medium cursor-pointer hover:text-blue-300">Home</Link>
        <Link to = "/about" className="mt-2 font-medium cursor-pointer hover:text-blue-300">About Us</Link>
        <Link to = "/property" className="mt-2 font-medium cursor-pointer hover:text-blue-300">Property Improvement & Construction</Link>
        <Link to = "/gallery" className="mt-2 font-medium cursor-pointer hover:text-blue-300">Gallery</Link>
        <Link to = "/contact" className="mt-2 font-medium cursor-pointer hover:text-blue-300">Contact</Link>
      </div>
    </div>

    {/* Section 3 */}
    <div className="items-center justify-center mt-10 md:pl-16">
      <span className="text-xl font-medium">Contact Us</span>
      <div className="flex flex-col w-full text-white">
        <span className="mt-6 font-medium">
          <i className="fa-solid fa-location-dot mr-2 white"></i>Brentwood, TN 37027</span>
        <span className="mt-6 font-medium">
          <i className="fa-solid fa-phone mt-2 text-white mr-2"></i>
          716-395-5040</span>
        <span className="mt-6 font-medium">
          <i class="fa-regular fa-message mt-2 text-white mr-2"></i>CorinLutz@PIICSTN.com</span>
      </div>
    </div>

    {/* Section 4 */}
    <div className="mt-4">
      <div className="flex grid grid-cols-4 mt-10 w-3/5">
        <i className="fa-brands fa-twitter text-white cursor-pointer hover:text-blue-300"></i>
        <i className="fa-brands fa-facebook text-white cursor-pointer hover:text-blue-300"></i>
        <i className="fa-brands fa-instagram text-white cursor-pointer hover:text-blue-300  "></i>
        <i className="fa-brands fa-pinterest text-white cursor-pointer hover:text-blue-300 "></i>
      </div>
    </div>
  </div>

  <div className="w-5/6 mx-auto border border-solid border-blue-400 mt-8"></div>

  <div className="text-white flex flex-col md:flex-row justify-between mt-4 w-5/6 mx-auto">
  <span className='mb-4 md:mb-0 md:mr-4 max-w-[400px]'>© Copyright 2023, Property All Rights Reserved</span>
  <span className='max-w-[400px]'>© Designed and Developed by Digi Technology</span>
</div>

</footer>

    </>
  )
}

export default Footer
