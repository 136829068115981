import React from 'react'
import '../styles/Contact.css'

const Contact = () => {
    return (
        <>
            <main className='contact-main'>
                <div className="contact-main-first">
                    <img className='hero-image' src="../assets/images/contact-hero.png" alt="" />
                    <div className="contact-main-first-content">
                        <h1>Let's Talk About</h1>
                        <h1>Your Dream Project!</h1>
                    </div>
                </div>
                <div className="contact-main-second">
                    <form className="main-second-left">
                        <p>It's fast, easy, and hassle-free!</p>
                        <h1>Get your <span>FREE</span> quote</h1>
                        <input type="text" placeholder='Your Name' />
                        <input type="text" placeholder='Your Zip Code' />
                        <input type="text" placeholder='Your Email' />
                        <textarea id="" cols="30" rows="10" placeholder='What Do You need To be Done'></textarea>
                        <button>Submit</button>
                    </form>
                    <div className="main-second-right">
                        <img src="../assets/images/contact-form.png" alt="" />
                    </div>
                </div>
                <div className="contact-main-third">
                    <div className="main-third-image">
                        <img src="../assets/images/contact-row-one.png" alt="" />
                    </div>
                    <div className="main-third-image">
                        <img src="../assets/images/contact-row-two.png" alt="" />
                    </div>
                    <div className="main-third-image">
                        <img src="../assets/images/contact-row-three.png" alt="" />
                    </div>
                    <div className="main-third-image">
                        <img src="../assets/images/contact-row-four.png" alt="" />
                    </div>
                </div>
                <div className="contact-main-fourth">
                    <div className="main-fourth-lefts">
                        <h1>Looking for something specific?</h1>
                        <p>Give me a call, we look forward to fulfilling all your requests!</p>
                        <button><i class="fa-solid fa-phone"></i> (716)-395-5949</button>
                    </div>
                    <div className="main-fourth-rights">
                        <img src="../assets/images/contact-image-four.png" alt="" />
                    </div>
                </div>
                <div className="contact-main-fifth">
                    <div className="fifth-top">
                        <p>Serve In The Middle Tennessee Area!</p>
                    </div>
                    <br />
                    <div className="fifth-bottom">
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-location-dot"></i>
                            <p>Brentwood, TN 37027</p>
                        </div>
                        <br />
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-phone"></i>
                            <p>(716)-395-5949</p>
                        </div>
                        <br />
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-envelope"></i>
                            <p>CorinLutz@PIICSTN.com</p>
                        </div>
                    </div>
                </div>
                <div className="contact-main-sixth">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103291.92885386034!2d-86.8667392465559!3d35.99897505849203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88647a54e7658aa1%3A0x22b346504468eaa3!2sBrentwood%2C%20TN%2037027%2C%20USA!5e0!3m2!1sen!2snp!4v1704351102702!5m2!1sen!2snp" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: "100%", height: "30rem" }}></iframe>
                </div>
            </main>
        </>
    )
}

export default Contact