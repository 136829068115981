// ImageModalContent.jsx
import React from 'react';

const ImageModalContent = ({ modalImgSrc, closeModal }) => {
  const handleOverlayClick = (event) => {
    // Check if the click was outside the image modal (on the overlay)
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="fixed top-0 left-0 z-80 w-screen h-screen bg-black/70 flex justify-center items-center" onClick={handleOverlayClick}>
      {/* A big image will be displayed here */}
      <img className="max-w-[800px] max-h-[600px] object-cover" src={modalImgSrc} alt="Modal" />
    </div>
  );
};

export default ImageModalContent;
