import React, { useState } from 'react';
import ImageModalContent from './ImageModalContent';
import { Link } from 'react-router-dom';

const Gallery = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState('');

  const openModal = (src) => {
    setModalOpen(true);
    setModalImgSrc(src);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const images = [
    '/assets/images/gallery1.jpg',
    '/assets/images/gallery2.jpg',
    '/assets/images/gallery3.jpg',
    '/assets/images/gallery4.jpg',
    '/assets/images/gallery5.jpeg',
    '/assets/images/gallery6.jpg',
  ];

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
        <div className="w-full md:relative inherit top-0">
          <img src="../assets/images/gallrey.png" className='w-full' alt="Your alt text" />
          <div className="absolute flex flex-col items-center md:inset-0 justify-center w-full md:w-1/2 mx-auto">
            <h1 className="md:text-white text-4xl font-medium font-semibold mt-4">
              Gallery
            </h1>
            <Link to={'/contact'} className="bg-blue-600 text-white mt-6 px-5 py-2 mt-2 rounded-md hover:bg-white hover:text-blue-500 transition duration-300">
              Contact Us
            </Link>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center py-20">
          <h1 className='text-2xl mt-16 md:mr-auto md:ml-32 md:text-4xl font-medium'>Projects</h1>

          {/* image grid division */}
          <div className="flex flex-wrap justify-evenly w-[90%] mt-16">
            {images.map((imageUrl, index) => (
              <div
                key={index}
                className="mb-8 rounded-md flex flex-col items-center justify-evenly w-[340px] h-[280px] overflow-hidden"
                onClick={() => openModal(imageUrl)}
              >
                <img src={imageUrl} className="w-full h-full rounded-lg object-cover cursor-pointer" alt="" />
              </div>
            ))}
          </div>
          {/* image grid division up to here */}

          {/* ImageModalContent */}
          {modalOpen && <ImageModalContent modalImgSrc={modalImgSrc} closeModal={closeModal} />}
        </div>
      </div>
    </>
  );
};

export default Gallery;
