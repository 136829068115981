import React from 'react'
import '../styles/Property.css'

const Property = () => {
    return (
        <>
            <main className='property-main'>
                <div className="property-main-first">
                    <img className='hero-image' src="../assets/images/property-img.png" alt="" />
                    <div className="property-main-first-content">
                        <h1>Punch lists, Site management / maintenance and Home Improvement</h1>
                        <br />
                        <button>CONTACT US</button>
                    </div>
                </div>
                <div className="property-main-third">
                    <div className="third-left">
                        <p>Title</p>
                        <br />
                        <p>At P.I.I.C.S. we are a creative, design professional company who will work with our clients to assess each need and design solutions to meet them, no matter the project. We source products and material to provide options for our clients and to fulfill the projects’ atmospheric impressions and expressions and to convey our clients’ special ideals.  Design consulting can vary drastically in scope and artistic direction, each with a unique set of plans.  We are proud to prepare an outline and/or drawing plans for each client’s unique space!
                            You can expect from us to collaborate with you and your team so we fully understand your vision.  It is our objective to discover and address your preferences, so each feature encourages the functionality of your design while adding a punch of flavor that fully compliments your plan.  We can even put together full floor plans to illustrate how a new design will integrate into an existing space.</p>
                        <br />
                        <p>Additionally, we can create built-in focal features, artwork and even furniture that will match your décor and layout!
                            We love to work one on one with each of our clients and aim to meet your budget and design requests in a fashion that will abide by your timeline! </p>
                    </div>
                    <br />
                    <div className="third-right">
                        <img src="../assets/images/property-two.png" alt="" />
                    </div>
                </div>
                <div className="property-main-second">
                    <div className="second-left">
                        <img src="../assets/images/property-one.png" alt="" />
                    </div>
                    <br />
                    <div className="second-right">
                        <p>Punchlist Completion</p>
                        <br />
                        <p>Our attention to details is exactly what you are looking for. We are
                            excited to be a part of the finishing process for your construction
                            projects. When you&#39;re ready to close out a project but need it to be
                            move-in ready we offer services to repair paint, patch minor drywall
                            imperfections, touch up caulking, construction site cleanup and
                            additional punch listing requests and more, just ask.</p>
                        <br />
                        <p>If you don&#39;t get paid until the punch list is complete, but are
                            stretched to your limits on bodies to do the final steps, call us. We
                            will come in and make your ends meet in a timely, professional, and
                            efficient manner. In addition, we will assist with tidying job site
                            efforts mid-project as well as final site cleanup. Our job is to get your
                            job wrapped up.</p>
                    </div>
                </div>
                <div className="property-main-third">
                    <div className="third-left">
                        <p>Site Management & Maintenance</p>
                        <br />
                        <p>At P.I.I.C.S. we understand that it&#39;s hard to be everywhere on your job
                            site all the time. A company is only as accomplished as their workers
                            allow them to be. Trying to keep track of who is accomplishing their
                            assigned tasks can be complicated when you can&#39;t be everywhere at
                            once.</p>
                        <br />
                        <p>We offer to be an independent supervisor and report to you when
                            workers are consistently not being productive. We will observe your
                            jobsite and confer with your Supervisors and Project Managers in a
                            discrete fashion to help improve your productivity.</p>
                        <br />
                        <p>Additionally, maintaining a clean work site is important for
                            productivity and the safety of workers. We would like to help you
                            stay on top of OSHA by cleaning up your site in and out of doors. We
                            would be happy to be your liaison for the strongest site relationships
                            and management.</p>
                    </div>
                    <br />
                    <div className="third-right">
                        <img src="../assets/images/property-two.png" alt="" />
                    </div>
                </div>
                <div className="property-main-fourth">
                    <div className="fourth-left">
                        <img src="../assets/images/property-third.png" alt="" />
                    </div>
                    <br />
                    <div className="fourth-right">
                        <p>Home Improvements</p>
                        <br />
                        <p>Have a project you&#39;ve wanted to accomplish, but are uncertain how
                            to go about it or what needs to be done? Let us help. We will listen to
                            your ideas and work with you until we fulfill your plans and hopes.</p>
                        <br />
                        <p>The owner of P.I.I.C.S. comes with a background in Architecture,
                            Landscaping, and various areas of construction from demolition to
                            finishing touches. An educational background in Art, Architecture,
                            and Construction has laid the groundwork for the wisdom to know what is possible in the setting presented and the ability to
                            understand how to make ideas work on a per-site and project basis.</p>
                    </div>
                </div>
                <div className="property-main-fifth">
                    <div className="fifth-left">
                        <p>Interested ?</p>
                        <br />
                        <p className='contact-p'>Send us any questions you have about our services and we'll get back to you within 48 hours.</p>
                        <br />
                        <br />
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-location-dot"></i>
                            <p>Brentwood, TN 37027</p>
                        </div>
                        <br />
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-phone"></i>
                            <p>(716)-395-5949</p>
                        </div>
                        <br />
                        <div className="contact-detail-fifth">
                            <i class="fa-solid fa-envelope"></i>
                            <p>CorinLutz@PIICSTN.com</p>
                        </div>
                    </div>
                    <br />
                    <form className="fifth-right">
                        <h1>Fill Your Questions Here</h1>
                        <input type="text" placeholder='Your Name' />
                        <input type="text" placeholder='Your Email' />
                        <textarea id="" cols="30" rows="10" placeholder='Your Question'></textarea>
                        <button>Send Question</button>
                    </form>
                </div>
            </main>
        </>
    )
}
export default Property