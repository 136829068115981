import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Homepage from './pages/Homepage';
import Property from './pages/Property';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path='/property' element={<Property/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/gallery' element = {<Gallery/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;